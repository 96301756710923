import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import Footer from "../components/Footer";
import AddBonds from "../components/Bonds";
import AddMF from "../components/MutualFund";
import AddFD from "../components/fixed_deposit";
function AddInvestment() {
    const auth = getAuth(app);
    const navigate = useNavigate();
    // const database = getDatabase(app)
    let [investmentType, setInvestmentType] = useState("Bonds")
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }, [navigate, auth]);

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <SideBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">Investment Type</label>
                                            <select
                                                className="form-control"
                                                id="Investmenttype" name="" onChange={(e) => { setInvestmentType(e.target.value) }}>
                                                <option value="Bonds">Bonds</option>
                                                <option value="MF">Mutual Funds</option>
                                                <option value="FD">Fixed Deposits</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {investmentType === "Bonds" && <AddBonds key={"bonds"} />}
                            {investmentType === "MF" && <AddMF key={"MF"} />}
                            {investmentType === "FD" && <AddFD key={"FD"} />}

                        </div>
                        {/* content-wrapper ends */}
                        {/* Footer start */}
                        <Footer />
                        {/* footer end */}
                    </div>
                </div >
            </div >
        </>
    )
}
export default AddInvestment;
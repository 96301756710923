import { app } from "./firebase";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import { getDatabase, ref, onValue, update } from "firebase/database";
import Footer from "../components/Footer";
import {  useNavigate } from "react-router-dom";
function AddClients() {
    const database = getDatabase(app);
    const navigate = useNavigate();
    let [clientName, setclientName] = useState("");
    let [clientAddress, setClientAddress] = useState("");
    let [clientPAN, setClientPAN] = useState("");
    let [clientDOB, setClientDOB] = useState("");
    let [clientEmail, setClientEmail] = useState("");
    let [clientPhone, setPhone] = useState("");
    let [clientMaritalStatus, setClientMaritalStatus] = useState("");
    let [rmCode, setRMCode] = useState("");
    let [rmName, setRMName] = useState("")
    let [clientType, setClientType] = useState("individual");
    let [cpEmail, setCPEmail] = useState("")
    let [cpPhone, setCPPhone] = useState("")
    let [cpName, setCPName] = useState("")
    let [cpLandline, setCPLandline] = useState("")
    let [cpDOB, setCPDOB] = useState("")
  

    useEffect(() => {
        getRMCode()
    })
    function getRMCode() {
        let uid = window.localStorage.getItem("uid");
        const rmRef = ref(database, `empDirectory/${uid}`)
        onValue(rmRef, (snapshot) => {
            let data = snapshot.val()
            setRMCode(`${data.empCode}`)
            setRMName(`${data.empName}`)
        })
    }

    const clientRef = ref(database, `clientDirectory/${rmCode}/${clientPAN}`);
    const submitClientDetails = async () => {
        if (clientPAN === "") {
            alert("Enter PAN")
        } else {
            if (clientType === "individual") {
                let details = {
                    clientName: `${clientName}`,
                    clientAddress: `${clientAddress}`,
                    clientPAN: `${clientPAN}`,
                    clientDOB: `${clientDOB}`,
                    clientEmail: `${clientEmail}`,
                    clientPhone: `${clientPhone}`,
                    rmCode: `${rmCode}`,
                    clientRM: `${rmName}`,
                    clientMaritalStatus: `${clientMaritalStatus}`,
                    clientType: `${clientType}`
                }
                update(clientRef, details).then(() => {
                    navigate("/add-investments")
                }).then(() => {

                })
            } else {
                let details = {
                    clientName: `${clientName}`,
                    clientAddress: `${clientAddress}`,
                    clientPAN: `${clientPAN}`,
                    clientDOB: `${clientDOB}`,
                    clientEmail: `${clientEmail}`,
                    clientPhone: `${clientPhone}`,
                    rmCode: `${rmCode}`,
                    clientRM: `${rmName}`,
                    clientMaritalStatus: `${clientMaritalStatus}`,
                    cpName: `${cpName}`,
                    cpDOB: `${cpDOB}`,
                    cpEmail: `${cpEmail}`,
                    cpPhone: `${cpPhone}`,
                    cpLandline: `${cpLandline}`,
                    clientType: `${clientType}`


                }
                update(clientRef, details).then(() => {
                    navigate("/add-investments")
                }).then(() => {

                })
            }
        }
    }
    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <SideBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">Client Type</label>
                                            <select
                                                className="form-control"
                                                id="Investmenttype" name="" onChange={(e) => { setClientType(e.target.value) }}
                                            >
                                                <option >--Select Client Type--</option>
                                                <option value="individual">Individual</option>
                                                <option value="corporate">Corporate</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Enter client's name"
                                                value={clientName}
                                                onChange={(e) => setclientName(e.target.value)}
                                                autoComplete="name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Adress"
                                                value={clientAddress}
                                                onChange={(e) => setClientAddress(e.target.value)}
                                            />
                                        </div>



                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">PAN</label>
                                            <input
                                                type="texgt"
                                                className="form-control"
                                                id="name1"
                                                placeholder="PAN number"
                                                value={clientPAN}
                                                onChange={(e) => setClientPAN(e.target.value.toUpperCase())}
                                                autoCapitalize="true"
                                                autoComplete="PAN"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">DOB</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="name1"
                                                placeholder="PAN number"
                                                value={clientDOB}
                                                onChange={(e) => setClientDOB(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">Email</label>
                                            <input
                                                type="mail"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Email"
                                                value={clientEmail}
                                                onChange={(e) => setClientEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Phone Number</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Phone number"
                                                value={clientPhone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                autoComplete="Phone"
                                            />
                                        </div>

                                    </div>

                                </div>
                                {/* Submit Button */}

                            </div>
                            {clientType === "individual" && (
                                <div className="container">
                                    <br></br>
                                    <div className="row">
                                        <div className="col-md-4">

                                            <div className="form-group">
                                                <label htmlFor="name1">Marital Status</label>
                                                <select
                                                    className="form-control"
                                                    id="Investmenttype" name="" onChange={(e) => { setClientMaritalStatus(e.target.value) }}
                                                >
                                                    <option >--Select Client Type--</option>
                                                    <option value="single">Single</option>
                                                    <option value="married">Married</option>
                                                </select>
                                            </div>


                                        </div>


                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <button
                                                type="submit"
                                                class="btn btn-primary btn-rounded btn-fw"
                                                onClick={submitClientDetails}
                                            >Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {clientType === "corporate" && (
                                <>
                                    <div className="container">
                                        <div className="row" style={{ height: "40px", marginTop: "20px" }}>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="name1" style={{ fontWeight: 500 }}>Contact Person's Details</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="name1">Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name1"
                                                        placeholder="Name"
                                                        value={cpName}
                                                        onChange={(e) => setCPName(e.target.value)}
                                                        autoComplete="name"
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="name1">Phone Number</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="name1"
                                                        placeholder="Phone number"
                                                        value={cpPhone}
                                                        onChange={(e) => setCPPhone(e.target.value)}
                                                        autoComplete="Phone"
                                                    />
                                                </div>



                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">
                                                    <label htmlFor="name1">DOB</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="name1"
                                                        placeholder="PAN number"
                                                        value={cpDOB}
                                                        onChange={(e) => setCPDOB(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="name1">Landline Number</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="name1"
                                                        placeholder="Landline number"
                                                        value={cpLandline}
                                                        onChange={(e) => setCPLandline(e.target.value)}
                                                        autoComplete="Phone"
                                                    />
                                                </div>


                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="name1">Email</label>
                                                    <input
                                                        type="mail"
                                                        className="form-control"
                                                        id="name1"
                                                        placeholder="Email"
                                                        value={cpEmail}
                                                        onChange={(e) => setCPEmail(e.target.value)}
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary btn-rounded btn-fw"
                                                    onClick={submitClientDetails}
                                                >Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                        {/* content-wrapper ends */}
                        {/* Footer start */}
                        <Footer />
                        {/* footer end */}
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddClients;
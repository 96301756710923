import { app } from "./firebase";
import Navbar from "../components/Navbar";
import { useEffect, useRef, useState } from "react";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import SideBar from "../components/Sidebar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EmpSale from "../components/EmpSales";
import { getDatabase, ref, onValue } from "firebase/database";
import AdminSale from "../components/AdminSales";

function Sales() {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const database = getDatabase(app)
  const uid = window.localStorage.getItem("uid");
  let [role, setRole] = useState("");

  function clearClientDetails() {
    window.localStorage.removeItem("name")
    window.localStorage.removeItem("rmCode")
    window.localStorage.removeItem("pan")
  }

  clearClientDetails()
  useEffect(() => {
    getUserInfo();
  })

  function getUserInfo() {
    const userRef = ref(database, `empDirectory/${uid}`)
    onValue(userRef, (snapshot) => {
      const data = snapshot.val()
      setRole(data.empRole);
    })
  }


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/");
      }
    });
    return () => unsubscribe();
  }, [navigate, auth]);

  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <SideBar />
          <div className="main-panel">
            <div className="content-wrapper">
              <Link to="/add-clients">
                <button
                  type="button"
                  class="btn btn-primary btn-rounded btn-fw"
                >
                  Add Clients
                </button>
              </Link>

              <div className="row mt-3">
                <div className="col-md grid-margin stretch-card">
                  <div className="card">
                    {
                      (role === 'admin' || 'hr') ? (<AdminSale />) : (<EmpSale />)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Sales;

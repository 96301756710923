import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { app } from "./firebase";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SideBar from "../components/Sidebar";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import MyDocument from "../components/leave_form";

function Leave() {
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [type, setType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reason, setReason] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      designation,
      department,
      employeeCode,
      leaveType,
      type,
      fromDate,
      toDate,
      reason,
    };

    setName(" ");
    setDesignation(" ");
    setDepartment(" ");
    setEmployeeCode(" ");
    setLeaveType(" ");
    setType(" ");
    setToDate(" ");
    setFromDate(" ");
    setReason(" ");

    const todayDate = new Date();
    const month = todayDate.getMonth() + 1;
    const day = todayDate.getDate();
    const hours = todayDate.getHours();
    const minutes = todayDate.getMinutes();
    const seconds = todayDate.getSeconds();
    const fileName = `Leave_application_${formData.name}_${day}-${month}-${hours}-${minutes}-${seconds}.pdf`;

    const blob = await pdf(<MyDocument formData={formData} />).toBlob();
    saveAs(blob, fileName);

    setSubmissionStatus(
      "Leave application submitted successfully. Redirecting to mail page in 4 seconds."
    );

    setTimeout(() => {
      sendMail(formData);
    }, 4000);
  };

  function sendMail(formData) {
    const toEmail = "ravikmathur@dimensiongroup.co.in";
    const ccEmail = "hr@dimensiongroup.co.in";
    const subject = "Request for Approval";
    const body = `Dear Sir,\n\nI'm writing this to you to request approval for my ${formData.type} leave.\n`;

    const mailtoLink = `mailto:${toEmail}?cc=${ccEmail}&subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  }
  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <SideBar />
          <div className="main-panel">
            <div className="content-wrapper">
              {submissionStatus && (
                <div className="alert alert-info">{submissionStatus}</div>
              )}
              <form className="form-row" onSubmit={handleSubmit}>
                <div className="col-md-4 mb-3">
                  <label>Employee Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Employee Name"
                    required
                    name="employeeName"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Designation"
                    required
                    name="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Department</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Department"
                    required
                    name="department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Employee Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Employee Code"
                    required
                    name="employeeCode"
                    value={employeeCode}
                    onChange={(e) => setEmployeeCode(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Leave</label>
                  <select
                    className="form-control"
                    required
                    name="leaveType"
                    value={leaveType}
                    onChange={(e) => setLeaveType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select leave type
                    </option>
                    <option value="Paid">Paid</option>
                    <option value="Casual">Casual</option>
                    <option value="RH">RH</option>
                    <option value="LOP">LOP</option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <label>Type</label>
                  <select
                    className="form-control"
                    required
                    name="type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="" disabled>
                      Select type
                    </option>
                    <option value="Full Day">Full Day Leave</option>
                    <option value="Half Day">Half Day Leave</option>
                    <option value="Short Leave">Short Leave</option>
                  </select>
                </div>
                <div className="col-md-4 mb-3">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    name="fromDate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    name="toDate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label>Reason</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Reason"
                    required
                    name="reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Leave;
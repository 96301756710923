import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC7Rr8aUawMKJMGxKVWPEO-FyOf_UzLmcQ",
  authDomain: "dimension-bf7ed.firebaseapp.com",
  databaseURL:
    "https://dimension-bf7ed-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dimension-bf7ed",
  storageBucket: "dimension-bf7ed.appspot.com",
  messagingSenderId: "925803819250",
  appId: "1:925803819250:web:19622cbc162405ce25606b",
  measurementId: "G-Z1BH9MGE36",
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
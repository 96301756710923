import { app } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Footer from "../components/Footer";
import { getDatabase, ref, set } from "firebase/database"
import { useLocation } from "react-router-dom";
import SideBar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function EditInvestments() {
    const auth = getAuth(app);
    const database = getDatabase(app)
    const navigate = useNavigate();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }, []); // Empty dependency array

    // const [clientName, setClientName] = useState("")
    const [clientEmail, setClientEmail] = useState("")
    const [totalProfit, setTotalProfit] = useState();

    const location = useLocation();
    const [pan, setPAN] = useState(location.state?.panNumber)
    const [rm, setRM] = useState(location.state?.rm)
    const [investment, setInvestment] = useState(location.state?.investment || {});

    const [bondISIN, setBondsISIN] = useState(location.state?.investment.bondISIN)
    const [bondName, setBondName] = useState(location.state?.investment.bondName)
    const [bondCallDate, setBondCallDate] = useState(location.state?.investment.bondCallDate);
    const [bondMaturityDate, setBondMaturityDate] = useState(location.state?.investment.bondMaturityDate)
    const [settlementDate, setSettlementDate] = useState(location.state?.investment.settlementDate)
    const [bondAmount, setBondAmount] = useState(location.state?.investment.bondAmount)
    const [purchasePrice, setPurchasePrice] = useState(location.state?.investment.purchasePrice);
    const [sellingPrice, setSellingPrice] = useState(location.state?.investment.sellingPrice);
    const [brokerage, setBrokerage] = useState(location.state?.investment.brokerage);
    const [profit, setProfit] = useState(location.state?.investment.profit);
    const [faceValue, setFaceValue] = useState(location.state?.investment.faceValue);
    const [bondQuantity, setBondQuantity] = useState(location.state?.investment.bondQuantity)
    const [company, setCompany] = useState(location.state?.investment.company);
    const [isAdmin, setIsAdmin] = useState(() => window.localStorage.getItem("isAdmin"));
    const [isVisible, setVisbility] = useState(location.state?.investment.isVisible);
    useEffect(() => {
        setProfit((sellingPrice - purchasePrice - brokerage).toFixed(4))
    }, [sellingPrice, purchasePrice, brokerage])
    useEffect(() => {
        setTotalProfit(profit * bondAmount)
    }, [profit, bondAmount])
    const clientRef = ref(database, `clientDirectory/${rm}/${pan}/Investments/Bonds/${bondISIN}`)
    useEffect(() => {
        if (isVisible === "true") {
            setVisbility(true)
        }
        if (isVisible === "false") {
            setVisbility(false)
        }
    }, [isVisible])
    console.log(bondISIN)
    const submitInvestment = async () => {

        const clientBondInvestmentDetails = {
            bondISIN: `${bondISIN}`,
            bondName: `${bondName}`,
            bondAmount: `${bondAmount}`,
            purchasePrice: `${purchasePrice}`,
            sellingPrice: `${sellingPrice}`,
            brokerage: `${brokerage}`,
            profit: `${profit}`,
            faceValue: `${faceValue}`,
            company: `${company}`,
            totalProfit: `${totalProfit}`,
            settlementDate: `${settlementDate}`,
            bondMaturityDate: `${bondMaturityDate}`,
            bondCallDate: `${bondCallDate}`,
            isVisible: `${isVisible}`
        }
        if (bondISIN && pan) {
            set(clientRef, clientBondInvestmentDetails).then(() => {
                setBondName("")
                setBondsISIN("")
                setBondAmount("")
                setPurchasePrice("")
                setPAN("")
                setBrokerage("")
                setProfit("")
                setSellingPrice("")
                setFaceValue("")
                setBondQuantity("")
                setCompany("")
                setBondMaturityDate("")
                setBondCallDate("")
                setSettlementDate("")
                setVisbility("")
                navigate("/sales")

            }).catch((error) => {
                console.log(error)
            })
        } else {
            alert("Please select PAN and ISIN")
        }

    }

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <SideBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <h2 htmlFor="name1">Edit Investment</h2>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label htmlFor="name1">Select PAN</label>

                                                <select
                                                    className="form-control"
                                                    id="clientPANSelect"
                                                    name="clientPAN"
                                                    required
                                                    readOnly
                                                >
                                                    <option value="">-{pan}</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">

                                        <div className="form-group">
                                            <label htmlFor="name1">ISIN</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name1"
                                                placeholder="ISIN"
                                                value={bondISIN}
                                                readOnly
                                            // onChange={(e) => setBondsISIN(e.target.value.toUpperCase())}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Call Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Call Date"
                                                value={bondCallDate}
                                                onChange={(e) => setBondCallDate(e.target.value)}
                                                autoComplete="amount"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="purchasePrice">Purchase Price</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="purchasePrice"

                                                placeholder="Purchase Price"
                                                value={purchasePrice} // Replace "bondName" with your state variable for purchase price
                                                onChange={(e) => setPurchasePrice(e.target.value)} // Update state variable for purchase price
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="profit">Profit</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="profit"
                                                placeholder="Profit"
                                                value={profit}
                                                // onChange={(e) => setProfit(e.target.value)}
                                                readOnly
                                            />
                                        </div>


                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">Security Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Security Name"
                                                value={bondName}
                                                onChange={(e) => setBondName(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Maturity Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Maturity Date"
                                                value={bondMaturityDate}
                                                onChange={(e) => setBondMaturityDate(e.target.value)}
                                                autoComplete="amount"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sellingPrice">Selling Price</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="sellingPrice"
                                                placeholder="Selling   
     Price"
                                                value={sellingPrice}
                                                onChange={(e) => setSellingPrice(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Company</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="name1"
                                                placeholder="ISIN"
                                                value={company}
                                                readOnly
                                            // onChange={(e) => setBondsISIN(e.target.value.toUpperCase())}
                                            />
                                        </div>

                                    </div>
                                    <div className="col-md-4">

                                        <div className="form-group">
                                            <label htmlFor="name1">Settlement Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Settlement Date"
                                                value={settlementDate}
                                                onChange={(e) => setSettlementDate(e.target.value)}
                                                autoComplete="amount"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Amount"
                                                value={bondAmount}
                                                onChange={(e) => setBondAmount(e.target.value)}
                                                autoComplete="amount"
                                            />
                                        </div>
                                        {
                                            isAdmin === "true" && (
                                                <div className="form-group">
                                                    <label htmlFor="brokerage">Brokerage</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="brokerage"
                                                        placeholder="Brokerage"
                                                        value={brokerage}
                                                        onChange={(e) => setBrokerage(e.target.value)}
                                                    />
                                                </div>
                                            )
                                        }
                                        <div className="form-group">
                                            <label htmlFor="name1">Approval</label>
                                            <select
                                                className="form-control"
                                                id="company" name="" onChange={(e) => { setVisbility(e.target.value) }} value={isVisible}>
                                                <option value="">--Select--</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                {/* Submit Button */}
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button
                                            type="submit"
                                            class="btn btn-primary btn-rounded btn-fw"
                                            onClick={submitInvestment}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>



                        </div>
                        {/* content-wrapper ends */}
                        {/* Footer start */}
                        <Footer />
                        {/* footer end */}
                    </div>
                </div >
            </div >
        </>
    )

}

export default EditInvestments  
import { useLocation } from "react-router-dom"
import { app } from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Footer from "../components/Footer";
import { getDatabase, ref, onValue, child, get, set, update } from "firebase/database"
import SideBar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function EditMF() {
    const location = useLocation();
    const auth = getAuth(app);
    const database = getDatabase(app)
    const navigate = useNavigate();
    let [AMC, setAMC] = useState(location.state?.investment.AMC)
    let [amount, setAmount] = useState(location.state?.investment.amount)
    let [company, setCompany] = useState(location.state?.investment.company);
    let [folioNumber, setFolioNumber] = useState(location.state?.investment.folioNumber)
    let [fundType, setFundType] = useState(location.state?.investment.fundType)
    let [investmentDate, setInvestmentDate] = useState(location.state?.investment.investmentDate)
    let [scheme, setScheme] = useState(location.state?.investment.scheme)
    let [brokerage, setBrokerage] = useState(location.state?.investment.brokerage)
    const [isVisible, setVisbility] = useState(location.state?.investment.isVisible);
    console.log(isVisible)
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate("/");
            }
        });
        return () => unsubscribe();
    }, [auth, navigate]);

    const [pan, setPAN] = useState(location.state?.panNumber)
    const [rm, setRM] = useState(location.state?.rm)
    const [investment, setInvestment] = useState(location.state?.investment || {});
    const clientRef = ref(database, `clientDirectory/${rm}/${pan}/Investments/MF/${folioNumber}`)
    const submitMF = async () => {
        if (folioNumber && pan) {
            const clientMFInvestmentDetails = {
                investmentDate: `${investmentDate}`,
                folioNumber: `${folioNumber}`,
                AMC: `${AMC}`,
                scheme: `${scheme}`,
                amount: `${amount}`,
                fundType: `${fundType}`,
                company: `${company}`,
                brokerage: `${brokerage}`,
                isVisible: `${isVisible}`
            };
            update(clientRef, clientMFInvestmentDetails).then(() => {
                navigate("/sales")
            }).catch((error) => {
                console.log(error)
            })
        } else {
            alert("Please select PAN and Folio Number")
        }
    }

    return (
        <>
            <div className="container-scroller">
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <SideBar />
                    <div className="main-panel">
                        <div className="content-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <h2 htmlFor="name1">Edit Mutual Fund</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="name1">Select PAN</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="amc"
                                                placeholder=""
                                                readOnly
                                                value={pan} // Replace "bondName" with your state variable for purchase price
                                            />
                                        </div>
                                    </div></div></div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">

                                        <div className="form-group">
                                            <label htmlFor="name1">AMC</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="amc"
                                                placeholder="AMC"
                                                readOnly
                                                value={AMC} // Replace "bondName" with your state variable for purchase price
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="purchasePrice">Amount</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="purchasePrice"
                                                placeholder="Amount"
                                                readOnly
                                                value={amount} // Replace "bondName" with your state variable for purchase price
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="purchasePrice">Brokerage</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="purchasePrice"
                                                placeholder="Brokerage"
                                                value={brokerage} // 
                                                onChange={(e) => { setBrokerage(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">


                                        <div className="form-group">
                                            <label htmlFor="purchasePrice">Scheme</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="purchasePrice"
                                                placeholder=""
                                                readOnly
                                                value={scheme} // Replace "bondName" with your state variable for purchase price
                                            />

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sellingPrice">Fund Type</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="purchasePrice"
                                                placeholder=""
                                                readOnly
                                                value={fundType} // Replace "bondName" with your state variable for purchase price
                                            />

                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Company</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="company"
                                                placeholder=""
                                                readOnly
                                                value={company} // Replace "bondName" with your state variable for purchase price
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                        <div className="form-group">
                                            <label htmlFor="name1">Folio Number</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="name1"
                                                placeholder="folio number"
                                                value={folioNumber}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="name1"
                                                placeholder="Date"
                                                value={investmentDate}
                                                readOnly
                                                autoComplete="date"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name1">Approval</label>
                                            <select
                                                className="form-control"
                                                id="company" name="" onChange={(e) => { setVisbility(e.target.value) }} value={isVisible}>
                                                <option value="">--Select--</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                        </div>

                                    </div>

                                </div>
                                {/* Submit Button */}
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button
                                            type="submit"
                                            class="btn btn-primary btn-rounded btn-fw"
                                            onClick={submitMF}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div >

                        </div>
                        {/* content-wrapper ends */}
                        {/* Footer start */}
                        <Footer />
                        {/* footer end */}
                    </div>
                </div >
            </div >
        </>
    )
}
export default EditMF
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { app } from "./firebase";
import Encriptor from "encriptorjs";
// import CryptoJS from "crypto-js";
import {
  getAuth, createUserWithEmailAndPassword, onAuthStateChanged,
  signInWithEmailAndPassword,
  // signOut,
} from "firebase/auth";
import { getDatabase, ref, set, onValue, update } from "firebase/database";
import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import Footer from "../components/Footer";

function Employee() {
  // function test() {
  //   const encryptionKey = '1851440745144129';
  //   const iv = '906858765sss6858839';
  //   const salt = '4534293823429419';
  //   const iterations = 1000;
  //   const keySize = 256 / 8;

  //   function deriveKey(password, salt, iterations) {
  //     const derivedKey = CryptoJS.PBKDF2(password, salt, {
  //       keySize: keySize, // 256-bit key
  //       iterations: iterations,
  //     });

  //     return derivedKey.toString(CryptoJS.enc.Hex);
  //   }

  //   function encryptData(plainText) {
  //     const derivedKey = deriveKey(encryptionKey, salt, iterations);

  //     // Encrypt using encriptorjs
  //     const encryptedData = Encriptor.encrypt(plainText, derivedKey, iv);
  //     return encryptedData;
  //   }

  //   function decryptData(encryptedData) {
  //     // Derive key and IV
  //     const derivedKey = deriveKey(encryptionKey, salt, iterations);
  //     const decryptedData = Encriptor.decrypt(encryptedData, derivedKey, iv);
  //     return decryptedData;
  //   }

  //   // Example usage
  //   const jsonData = {
  //     "objcalc": {
  //       "CertDate": "2022-11-10",
  //       "dblInvamt": "75000",
  //       "strComp": "SFL",
  //       "strInvType": "SDR",
  //       "strSCFlag": "N",
  //       "strTenure": "60",
  //       "strscheme": "N",
  //       "strSubtype": "Normal",
  //       "strGender": "F"
  //     }
  //   }
  //   const jsonString = JSON.stringify(jsonData);
  //   // console.log(jsonString)

  //   const encryptedText = encryptData(jsonString);
  //   console.log(encryptedText);

  //   const decryptedText = decryptData(encryptedText);
  //   console.log(decryptedText);
  // }
  // test();

  const auth = getAuth(app);
  const navigate = useNavigate();
  let [empCode, setInputValue1] = useState("");
  let [empName, setInputValue3] = useState("");
  let [empofficialMobileNumber, setInputValue2] = useState("");
  let [empOfficialMail, setInputValue5] = useState("");
  let [empPersonalMobileNumber, setInputValue4] = useState("");
  let [empDOJ, setInputValue6] = useState("");
  let [empDesignation, setInputValue7] = useState("");
  let [empRole, setInputValue9] = useState("");

  const database = getDatabase(app);
  // const empDirectory = push(ref(database, "empDirectory"));


  function createUser() {
    // const passRef = ref(database, "defaultPassword");
    createUserWithEmailAndPassword(auth, empOfficialMail, "dimension")
      .then((userCredential) => {
        let user = userCredential.user;
        console.log("New user:", user.uid);
        let uid = user.uid;
        const addEmp = async () => {
          let empData = {
            empCode: `${empCode}`,
            empName: `${empName}`,
            empofficialMobileNumber: `${empofficialMobileNumber}`,
            empOfficialMail: `${empOfficialMail}`,
            empDesignation: `${empDesignation}`,
            empPersonalMobileNumber: `${empPersonalMobileNumber}`,
            empDOJ: `${empDOJ}`,
            empRole: `${empRole}`,
          };

          if (empCode &&
            empName &&
            empDOJ &&
            empofficialMobileNumber &&
            empDesignation &&
            empOfficialMail &&
            empPersonalMobileNumber &&
            empRole) {
            createUser();
            console.log();
            const empRef = ref(database, "empDirectory/" + `${uid}`);
            set(empRef, empData)
              .then(() => {
                console.log("Saved Data");
              })
              .catch((error) => {
                if (error.message === "PERMISSION_DENIED: Permission denied") {
                  alert("Permission Denied");
                }
              });
          } else {
            alert("Enter all details");
          }
        };
        addEmp()
        const roleRef = ref(database, "role/");
        if (empRole === "admin") {
          const roleData = {};
          roleData[user.uid] = "admin";
          update(roleRef, roleData)
            .then(() => {
            })
            .catch((error) => {
              console.log(error);
            });
        }
        if (empRole === "hr") {
          const roleData = {};
          roleData[user.uid] = "hr";
          update(roleRef, roleData)
            .then(() => {
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const secret = ref(database, "secretKey");
        onValue(secret, (snapshot) => {
          const key = snapshot.val();
          const email = window.localStorage.getItem("email");
          const encryptedPass = window.localStorage.getItem("key");
          const decryptedPass = Encriptor.decrypt(encryptedPass, key);
          signInWithEmailAndPassword(auth, email, decryptedPass)
            .then(() => {
            })
            .catch((error) => {
              console.log(error);
              navigate("/");
            });
        });
      }
      if (!user) {
        console.log("Not authenticated");
        navigate("/");
      }
    });
    return () => unsubscribe();
  }, [auth, navigate, database]);

  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper">
          <SideBar />
          {/* Main Page Start Here  */}
          <div className="main-panel">
            <div className="content-wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name1">Emp Code</label>
                      <input
                        type="number"
                        className="form-control"
                        id="name1"
                        placeholder="Enter Emp Code"
                        value={empCode}
                        onChange={(e) => setInputValue1(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="text1">Official Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        id="text1"
                        placeholder="Enter Official Mobile Number"
                        value={empofficialMobileNumber}
                        onChange={(e) => setInputValue2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name2">Emp Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name2"
                        placeholder="Enter Emp Name"
                        value={empName}
                        onChange={(e) => setInputValue3(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="text2">Personal Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        id="text2"
                        placeholder="Enter Personal Mobile Number"
                        value={empPersonalMobileNumber}
                        onChange={(e) => setInputValue4(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name3">Official Mail Id</label>
                      <input
                        type="mail"
                        className="form-control"
                        id="name3"
                        placeholder="Enter Official Mail Id"
                        value={empOfficialMail}
                        onChange={(e) => setInputValue5(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="text3">Date of Joining</label>
                      <input
                        type="date"
                        className="form-control"
                        id="text3"
                        placeholder="Enter DOJ"
                        value={empDOJ}
                        onChange={(e) => setInputValue6(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name1">Designation</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name1"
                        placeholder="Enter Designation"
                        value={empDesignation}
                        onChange={(e) => setInputValue7(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="name1">Role</label>
                      <select
                        className="form-control"
                        id="name1"
                        name="pets"
                        placeholder="Role"
                        value={empRole}
                        onChange={(e) => setInputValue9(e.target.value)}
                      >
                        <option value="">Select Role</option>
                        <option value="employee">Employee</option>
                        <option value="admin">Admin</option>
                        <option value="hr">HR</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="row mt-3">
                  <div className="col-md-12">
                    <button
                      type="submit"
                      class="btn btn-primary btn-rounded btn-fw"
                      onClick={createUser}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
            {/* Footer start */}
            <Footer />
            {/* footer end */}
          </div>
          {/* main-panel ends */}
        </div>
        {/* page-body-wrapper ends */}
      </div>
    </>
  );
}

export default Employee;
import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          Dimension HRMS © 2024.{" "}
          <Link to="#" target="_blank">
            HRMS
          </Link>{" "}
          from Dimension Developers.
        </span>
        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          Hand-crafted &amp; made with{" "}
          <i className="ti-heart text-danger ml-1" />
        </span>
      </div>
      {/* <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Distributed by{" "}
                  <Link to="#" target="_blank">
                    Themewagon
                  </a>
                </span>
              </div> */}
    </footer>
  );
}

export default Footer;